import { DataSource, DataSourceId } from './data-source';

/**
 * A strongly-typed GeoDataset ID.
 */
export type GeoDatasetId = number;

export interface GeoDataset {
  id: GeoDatasetId;
  modelId: number;
  categoryId: number | null;
  iconId: number | null;
  /**
   * Gets the data view icon URI.
   */
  iconUri: string | null;
  name: string;
  description: string;
  properties?: GeoDatasetProperties | string;
  dateCreated: Date;
  updated: Date;
  sortOrder: number | null;
  geoDatasetExternalLayers?: GeoDatasetExternalLayer[] | null;
  geoDatasetInternalLayers?: GeoDatasetInternalLayer[] | null;
}

export interface GeoDatasetMap {
  geoDatasetExternalLayers?: GeoDatasetExternalLayer[] | null;
  geoDatasetInternalLayers?: GeoDatasetInternalLayer[] | null;
}

export interface GeoDatasetProperties {
  centreLat: number;
  centreLong: number;
  zoomFactor: number;
}

export type GeoDatasetPropertiesResult = {
  geoDatasetId: GeoDatasetId;
} & GeoDatasetProperties;

export interface GeoCoordinates {
  fromLatitudeColumn: string;
  fromLongitudeColumn: string;
  toLatitudeColumn: string;
  toLongitudeColumn: string;
  fillColor: string;
  sourceColor: string;
  targetColor: string;
  width: string;
  tooltip: string;
}

/**
 * A strongly-typed GeoDataset external layer ID.
 */
export type GeoDatasetExternalLayerId = number;

export interface GeoDatasetExternalLayer {
  id: GeoDatasetExternalLayerId;
  geoDataSetId: GeoDatasetId;
  name: string;
  connectionId: DataSourceId;
  connection: DataSource;
  tableName?: string;
  layerOrder: number;
  dataLayerType: number;
  layerSettings?: GeoDatasetExternalLayerSettings | string;
  properties?: GeoDatasetExternalLayerProperties[] | string | null;
  featureProperties?: GeoDatasetExternalLayerFeatureProperty[] | string | null;
  geoFeatureProperties: GeoDatasetExternalLayerGeoFeatureProperties[];
  fileBlob: string | null;
  fileUri: string | null;
  minZoom?: number;
  maxZoom?: number;
  enabled: boolean;
}

export interface GeoDatasetExternalLayerSettings {
  color: string;
  width: number;
  opacity: number;
  filled: boolean;
}

export interface GeoDatasetExternalLayerGeoFeatureProperties {
  ID: number;
  Model: string;
  Scenario: string;
  [key: string]: string | number;
}

export interface GeoDatasetExternalLayerProperties {
  [key: string]: boolean;
}

export interface GeoDatasetExternalLayerFeatureProperty {
  featurePropertyName: string;
  configType: string;
  tableJoinColumn: string;
  tableInfoColumn: string;
  active: boolean;
}

export type LayerGeoJsonData = {
  type: string;
  features: {
    type: string;
    properties: Record<string, any>;
  }[];
};

export type GeoDatasetExternalLayerJsonData = {
  id: number;
  name: string;
  data: string;
};

export type BlobStorageItem = {
  blobName: string;
  blobUrl: string;
};

export type BlobItemContent = {
  name: string;
  content: string;
};

/**
 * A strongly-typed GeoDataset internal layer ID.
 */
export type GeoDatasetInternalLayerId = number;

export interface GeoDatasetInternalLayer {
  id: GeoDatasetInternalLayerId;
  geoDataSetId: GeoDatasetId;
  name: string;
  connectionId: DataSourceId;
  connection: DataSource;
  tableName: string;
  dataLayerType: number;
  layerSettings?: {
    tooltip?: string;
  } & GeoDatasetInternalLayerSettings;
  layerOrder: number;
  minZoom?: number;
  maxZoom?: number;
  geoCoordinates: GeoCoordinates[];
  enabled: boolean;
}

export type GeoDatasetInternalLayerSettings =
  | GeoDataseDataLayerLine
  | GeoDataseDataLayerArc
  | GeoDataseDataLayerCircle
  | string;

export interface GeoDatasetDataLayerType {
  id: GeoDatasetId;
  name: string;
}

interface GeoDatasetDataLayerBase {
  fromLatitudeColumn: string;
  fromLongitudeColumn: string;
  toLatitudeColumn: string;
  toLongitudeColumn: string;
  visible: boolean;
}

export interface GeoDataseDataLayerCircle {
  filled: boolean;
  fromLatitudeColumn: string;
  fromLongitudeColumn: string;
  width: number;
  fillColor: string;
  visible: boolean;
}

export interface GeoDataseDataLayerLine extends GeoDatasetDataLayerBase {
  sourceColor: string;
  targetColor: string;
  lineColor: string;
  width: number;
}

export interface GeoDataseDataLayerArc extends GeoDatasetDataLayerBase {
  sourceColor: string;
  targetColor: string;
  width: string;
}

export interface GeoDatasetColour {
  applicationId: number;
  colourId: number;
  colour: string;
}

/**
 * Request payload used to create the data view.
 */
export type CreateGeoDatasetPayload = Omit<
  GeoDataset,
  'id' | 'iconUri' | 'dateCreated' | 'updated'
>;

/**
 * Request payload used to update an existing data view.
 */
export type UpdateGeoDatasetPayload = CreateGeoDatasetPayload;

export type UseUpdateGeoDatasetPayload = UpdateGeoDatasetPayload & {
  geoDatasetId: GeoDatasetId;
};

export enum GeoDatasetLayerType {
  Line = 1,
  Arc = 2,
  Circle = 3,
}

export enum GeoDatasetExternalLayerFeaturePropertyConfigType {
  AreaColour = 'Area Colour',
  LineThickness = 'Line Thickness',
  LineColour = 'Line Colour',
}
